import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Hero from '../content/Hero';
import Meta from '../../shared/Meta';
import CostEstimator from '../content/CostEstimator';
import Triple from '../content/Triple';
import VendorsToChooseFrom from '../VendorsToChooseFrom';
import CtaImage from '../content/CtaImage';

// Actions
import { clearServerRenderedPath } from '../../../actions/uiActions';

// Selectors
import { getCityConfig } from '../../../selectors';

// Misc
import IsoMorphUtils from '../../../modules/isoMorphUtils';
import { getUrl } from '../../../modules/helpers';
import styles from '../../../../stylesheets/public/layouts/AboutPage.module.postcss';
import tripleStyles from '../content/Triple.module.postcss';

class CorporateCatering extends Component {
  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  render() {
    const { location, cityConfig } = this.props;

    const vehicleType = cityConfig.vehicle_type.toLowerCase();

    return (
      <div className="CateringPage">
        <Meta
          title={`Food ${cityConfig.vehicle_type} Office & Corporate Catering | ${cityConfig.site_title}`}
          type="website"
          url={getUrl()}
          description={`Reward your employees with an office lunch from a gourmet kitchen on wheels! Use our free request form for quotes from ${cityConfig.city_name}'s best food ${vehicleType}s.`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Office Catering",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />

        <Hero photo="https://static.seattlefoodtruck.com/photos/home5.png">
          <h1>Office Catering</h1>
          <p>Reward your employees with an office lunch from a gourmet kitchen on wheels! Use our free request form below for quotes from {cityConfig.city_name}'s best food {vehicleType}s.</p>
          <a href="/catering/register" className="Button">
            Get Started
          </a>
        </Hero>

        <section className="NewSection">
          <h3>How it works</h3>
          <div className={tripleStyles.Triple_wrapper}>
            <Triple
              image="https://static.seattlefoodtruck.com/photos/catering1.png"
              title="1. Tell Us About Your Event"
              text={`Answer a few questions about your upcoming event, and we'll reach out to over 500 of ${cityConfig.city_name}'s best food ${vehicleType}s.`}
            />
            <Triple
              image="https://static.seattlefoodtruck.com/photos/catering2.png"
              title="2. Compare Quotes"
              text={`Read reviews, pricing and menus from ${cityConfig.city_name}'s best food ${vehicleType}s. Message directly with vendors via our online platform.`}
            />
            <Triple
              image="https://static.seattlefoodtruck.com/photos/catering3.png"
              title="3. Book Your Favorite"
              text={`Book your food ${vehicleType} knowing you're getting the best deal, with no added service fees or commission.`}
            />
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/OfficeCater1.jpeg" imgClass="img-officeCater1"/>
              <div className={styles.Cta_copy}>
                <h4>Ditch The Boring Office Catering</h4>

                <p>With {cityConfig.site_title} you'll get access to over 500 of {cityConfig.city_name}'s best food {vehicleType}s to help treat your employees to made-to-order catering right on site! Guests can order at the truck or have the food set up inside as our vendors can accommodate any venue. Whether it's delicious deep-dish pizza from Delfino's, spicy chicken curry from NaanSense, or fresh Hawaiian poke from Sam Choy's Poke To The Max, our vendors can create an office catering experience that guests will love.</p>

                <div>
                <a href="/catering/register" className="Button">
                  Free Quote
                </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta + " " + styles.Cta__reversed}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/OfficeCater2.jpeg" imgClass="img-officeCater2"/>
              <div className={styles.Cta_copy}>
                <h4>We'll handle the hard work</h4>

                <p>No more wasting time reaching out to multiple restaurants, waiting for a call back on their timeline. Send a request for your upcoming event and we'll contact {cityConfig.city_name}'s best food {vehicleType}s on your behalf. Available vendors will respond via our platform with menu options, pricing, and details. You'll be able to compare quotes, read vendor reviews, and choose your favorite food {vehicleType} from our easy-to-use catering dashboard, all in a couple of hours, not days!</p>

                <div>
                  <a href="/catering/register" className="Button">
                    Book a Food {cityConfig.vehicle_type}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <VendorsToChooseFrom />

        <CostEstimator title="Office Catering Cost Estimator" />

        <section className="NewSection u-textCenter">
          <h3>Our Happy Customers</h3>
          <div className="u-grid-row u-mt2">
            <div className="u-grid-col u-grid-md-quarter u-grid-sm-half u-mb3">
              <div className={styles.CateringCustomerImg} style={{backgroundImage: 'url(https://static.seattlefoodtruck.com/photos/Praveen.jpeg)'}}/>
              <h6>Praveen - Redmond, WA</h6>
              <p>Our team was over the basic boxed lunch catering. We hired a different food {vehicleType} each Friday to give our employees something new each week parked right outside the office.</p>
            </div>
            <div className="u-grid-col u-grid-md-quarter u-grid-sm-half u-mb3">
              <div className={styles.CateringCustomerImg} style={{backgroundImage: 'url(https://static.seattlefoodtruck.com/photos/Dana.jpeg)'}}/>
              <h6>Dana - Kent, WA</h6>
              <p>I wanted to make sure our office lunch program was on auto-pilot. With a simple request, we received quotes from dozens of vendors bidding for our events. So simple to use!</p>
            </div>
            <div className="u-grid-col u-grid-md-quarter u-grid-sm-half u-mb3">
              <div className={styles.CateringCustomerImg} style={{backgroundImage: 'url(https://static.seattlefoodtruck.com/photos/Heidi.jpeg)'}}/>
              <h6>Heidi - Bothell, WA</h6>
              <p>Quick response time from so many vendors. We were able to work directly with each bid to narrow down prices, menus, and dietary restrictions. Highly recommended!</p>
            </div>
            <div className="u-grid-col u-grid-md-quarter u-grid-sm-half u-mb3">
              <div className={styles.CateringCustomerImg} style={{backgroundImage: 'url(https://static.seattlefoodtruck.com/photos/Khea.jpeg)'}}/>
              <h6>Khea - Seattle, WA</h6>
              <p>Food {vehicleType}s were a perfect way to treat our team. They arrived on time, set up in our parking lot, and left with no mess in a couple of hours.</p>
            </div>
          </div>
        </section>

        <section className="NewSection">
          <div className={styles.BookingCta}>
            <h3>Think of us for your next event</h3>
            <p>Free no-obligation estimates from {cityConfig.city_name}'s best food {vehicleType}s.</p>
            <a
              className="Button"
              href="/catering/register"
            >
              Food {cityConfig.vehicle_type} Catering
            </a>
          </div>
        </section>
      </div>
    );
  }
}

CorporateCatering.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateCatering);
